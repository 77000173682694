import * as React from 'react'

import Banner from '../components/banner'
import Layout from '../components/layout'

export default function AboutUsPage(): JSX.Element {
  return (
    <Layout>
      <div id="aboutUs">
        <Banner bannerImage="Conversation" textColor="black" bannerText="About Youth Genes" />
        <h3>Our Goal</h3>
        <div className="textWrapper">
          <p className="indent">
            Gen Z is hyper exposed to many issues thanks to social media like Twitter, Instagram,
            and Tiktok. We are more aware of political, social, and medical current events than
            previous generations at out age. Our goal is to connect youth to the world of genetics.
          </p>
          <p className="indent">
            Genes are the core building blocks of human life. As society progresses and our
            surroundings change, so do our genes. We think it is vital for the next generation to
            get involved in the ever-changing world of genetics. Despite the importance of genetic
            education, we find that our school systems are inadequate in representing genetic
            careers and the critical work that geneticists do. We at Youth Genes hope to remedy this
            problem by connecting teenagers and high schoolers to genetics professors in hopes of
            inspiring them to pursue careers in the area. There is never a dull moment in genetics.
          </p>
        </div>

        <div className="hrBreak">
          <hr />
        </div>

        <h3>Meet our Mentor</h3>
        <div className="textWrapper">
          <p className="indent">
            Alexa Geltzeiler is a clinical and research genetic counselor in the department of
            pediatrics at Columbia University Irving Medical Center. She works on the Simons
            Searchlight project, one of the largest studies of the genetic causes of autism and
            neurodevelopmental disorders ever undertaken. Alexa has been interested in genetics
            since middle school, so she now has a passion for engaging young people in discussions
            about genetics. Throughout the creation of Youth Genes, Alexa has helped moderate
            content and serve as a consultant for the development team.
          </p>
        </div>
      </div>
    </Layout>
  )
}
